import _streamSplicer from "stream-splicer";
import _inherits from "inherits";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Splicer = _streamSplicer;
var inherits = _inherits;
exports = Labeled;
inherits(Labeled, Splicer);

exports.obj = function (streams, opts) {
  if (!opts) opts = {};
  opts.objectMode = true;
  return new Labeled(streams, opts);
};

function Labeled(streams, opts) {
  if (!((this || _global) instanceof Labeled)) return new Labeled(streams, opts);
  Splicer.call(this || _global, [], opts);
  var reps = [];

  for (var i = 0; i < streams.length; i++) {
    var s = streams[i];
    if (typeof s === "string") continue;

    if (Array.isArray(s)) {
      s = new Labeled(s, opts);
    }

    if (i >= 0 && typeof streams[i - 1] === "string") {
      s.label = streams[i - 1];
    }

    reps.push(s);
  }

  if (typeof streams[i - 1] === "string") {
    reps.push(new Labeled([], opts));
  }

  (this || _global).splice.apply(this || _global, [0, 0].concat(reps));
}

Labeled.prototype.indexOf = function (stream) {
  if (typeof stream === "string") {
    for (var i = 0; i < (this || _global)._streams.length; i++) {
      if ((this || _global)._streams[i].label === stream) return i;
    }

    return -1;
  } else {
    return Splicer.prototype.indexOf.call(this || _global, stream);
  }
};

Labeled.prototype.get = function (key) {
  if (typeof key === "string") {
    var ix = this.indexOf(key);
    if (ix < 0) return undefined;
    return (this || _global)._streams[ix];
  } else return Splicer.prototype.get.call(this || _global, key);
};

Labeled.prototype.splice = function (key) {
  var ix;

  if (typeof key === "string") {
    ix = this.indexOf(key);
  } else ix = key;

  var args = [ix].concat([].slice.call(arguments, 1));
  return Splicer.prototype.splice.apply(this || _global, args);
};

export default exports;
export const obj = exports.obj;